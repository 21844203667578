@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-display: auto;
  font-weight: 400;
  src: url("../fonts/Inter-Regular.woff2") format("woff2"),
       url("../fonts/Inter-Regular.woff") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-display: auto;
  font-weight: 400;
  src: url("../fonts/Inter-Italic.woff2") format("woff2"),
       url("../fonts/Inter-Italic.woff") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-display: auto;
  font-weight: 600;
  src: url("../fonts/Inter-Medium.woff2") format("woff2"),
       url("../fonts/Inter-Medium.woff") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-display: auto;
  font-weight: 600;
  src: url("../fonts/Inter-MediumItalic.woff2") format("woff2"),
       url("../fonts/Inter-MediumItalic.woff") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-display: auto;
  font-weight: 800;
  src: url("../fonts/Inter-Bold.woff2") format("woff2"),
       url("../fonts/Inter-Bold.woff") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-display: auto;
  font-weight: 800;
  src: url("../fonts/Inter-BoldItalic.woff2") format("woff2"),
       url("../fonts/Inter-BoldItalic.woff") format("woff");
}


/* inria-sans-300 - latin-ext_latin */
@font-face {
  font-family: 'Inria Sans';
  font-style: normal;
  font-weight: 300;
  src: local(''),
       url('../fonts/inria-sans-v12-latin-ext_latin-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/inria-sans-v12-latin-ext_latin-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* inria-sans-300italic - latin-ext_latin */
@font-face {
  font-family: 'Inria Sans';
  font-style: italic;
  font-weight: 300;
  src: local(''),
       url('../fonts/inria-sans-v12-latin-ext_latin-300italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/inria-sans-v12-latin-ext_latin-300italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* inria-sans-regular - latin-ext_latin */
@font-face {
  font-family: 'Inria Sans';
  font-style: normal;
  font-weight: 400;
  src: local(''),
       url('../fonts/inria-sans-v12-latin-ext_latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/inria-sans-v12-latin-ext_latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* inria-sans-italic - latin-ext_latin */
@font-face {
  font-family: 'Inria Sans';
  font-style: italic;
  font-weight: 400;
  src: local(''),
       url('../fonts/inria-sans-v12-latin-ext_latin-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/inria-sans-v12-latin-ext_latin-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* inria-sans-700 - latin-ext_latin */
@font-face {
  font-family: 'Inria Sans';
  font-style: normal;
  font-weight: 700;
  src: local(''),
       url('../fonts/inria-sans-v12-latin-ext_latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/inria-sans-v12-latin-ext_latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* inria-sans-700italic - latin-ext_latin */
@font-face {
  font-family: 'Inria Sans';
  font-style: italic;
  font-weight: 700;
  src: local(''),
       url('../fonts/inria-sans-v12-latin-ext_latin-700italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/inria-sans-v12-latin-ext_latin-700italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* lexend-200 - latin-ext_latin */
@font-face {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 200;
  src: local(''),
       url('../fonts/lexend-v12-latin-ext_latin-200.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/lexend-v12-latin-ext_latin-200.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* lexend-regular - latin-ext_latin */
@font-face {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 400;
  src: local(''),
       url('../fonts/lexend-v12-latin-ext_latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/lexend-v12-latin-ext_latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* lexend-600 - latin-ext_latin */
@font-face {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 600;
  src: local(''),
       url('../fonts/lexend-v12-latin-ext_latin-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/lexend-v12-latin-ext_latin-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* lexend-800 - latin-ext_latin */
@font-face {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 800;
  src: local(''),
       url('../fonts/lexend-v12-latin-ext_latin-800.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/lexend-v12-latin-ext_latin-800.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}


/* lexend-exa-200 - latin-ext_latin */
@font-face {
  font-family: 'Lexend Exa';
  font-style: normal;
  font-weight: 200;
  src: local(''),
       url('../fonts/lexend-exa-v20-latin-ext_latin-200.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/lexend-exa-v20-latin-ext_latin-200.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* lexend-exa-regular - latin-ext_latin */
@font-face {
  font-family: 'Lexend Exa';
  font-style: normal;
  font-weight: 400;
  src: local(''),
       url('../fonts/lexend-exa-v20-latin-ext_latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/lexend-exa-v20-latin-ext_latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* lexend-exa-600 - latin-ext_latin */
@font-face {
  font-family: 'Lexend Exa';
  font-style: normal;
  font-weight: 600;
  src: local(''),
       url('../fonts/lexend-exa-v20-latin-ext_latin-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/lexend-exa-v20-latin-ext_latin-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* lexend-exa-800 - latin-ext_latin */
@font-face {
  font-family: 'Lexend Exa';
  font-style: normal;
  font-weight: 800;
  src: local(''),
       url('../fonts/lexend-exa-v20-latin-ext_latin-800.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/lexend-exa-v20-latin-ext_latin-800.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
